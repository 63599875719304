var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white",
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("testator-search-status")) + " ")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-4 col-md-4 col-12"
  }, [_c('div', {
    staticClass: "sidebar sticky-bar p-4 rounded shadow bg-light"
  }, [_c('div', {
    staticClass: "widget mb-4 pb-4 border-bottom"
  }, [_c('div', {
    staticClass: "widget-search mt-4 mb-0",
    attrs: {
      "id": "jobkeywords"
    }
  }), _c('router-link', {
    staticClass: "btn w-100",
    class: _vm.statusPart == 1 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'carian.status-a',
        query: {
          user_id: _vm.user_id
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("will")))]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.statusPart == 2 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'carian.status-b',
        query: {
          user_id: _vm.user_id
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("testator")))]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.statusPart == 3 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'carian.status-c',
        query: {
          user_id: _vm.user_id
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("agent")))]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.statusPart == 4 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'carian.status-d',
        query: {
          user_id: _vm.user_id
        }
      }
    }
  }, [_vm._v("Status")]), _vm.wasiat_status == 2 && _vm.userRole == 'Staff' ? _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.statusPart == 5 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'carian.status-e',
        query: {
          user_id: _vm.user_id,
          wasiat_id: this.wasiat_id,
          amendment_id: this.amendment_id
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("printwill")))]) : _vm._e()], 1)])]), _c('router-view')], 1)])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }